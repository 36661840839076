import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import { Container } from 'reactstrap'
import SEO from "../components/seo"

import Layout from "../components/Layout"
import Banner from '../components/Banner'
import CardExam from '../components/CardExam'

import * as S from '../styles/servicos'

export default () => {
  const { banner, site: { siteMetadata : about }  } = useStaticQuery(
    graphql`
      query {
        banner: file(relativePath: {eq: "banner-services.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        site {
          siteMetadata {
            address
            email
            phone
          }
        }
      }
    `
  )
  about.phoneLink = about.phone.replace(/\D/gim, '');

  return (
    <Layout>
      <SEO title="Serviços" description="Conheça nossos exames" />
      <Banner images={banner}/>
      <Container className="pt-4">
        <S.ContainerText>
          <h3>Nós amamos o que fazemos!</h3>
          <p>Conheça nossos exames.</p>
        </S.ContainerText>
        <S.ContainerExam>
          <CardExam propName="Ultrassonografia abdominal" propLink="/servicos/ultrassonografia-abdominal" />
          <CardExam propName="Ultrassonografia gestacional" propLink="/servicos/ultrassonografia-gestacional" />
          <CardExam propName="Ultrassonografia Ocular" propLink="/servicos/ultrassonografia-ocular" />
          <CardExam propName="Ultrassonografia cervical" propLink="/servicos/ultrassonografia-cervical" />
          <CardExam propName="Ultrassonografia Doppler Hepática" propLink="/servicos/ultrassonografia-doppler-hepatica" />
          <CardExam propName="Ultrassonografia Doppler Renal" propLink="/servicos/ultrassonografia-doppler-renal" />
          <CardExam propName="Ecodopplercardiograma" propLink="/servicos/ecodopplercardiograma" />
          <CardExam propName="Eletrocardiograma" propLink="/servicos/eletrocardiograma" />
        </S.ContainerExam>
      </Container>
    </Layout>
  )
}
