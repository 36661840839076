import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'

import * as S from './styled'

const CardExam = ({ propName, propLink }) => {
  return (
    <S.Card>
      <h5>{propName}</h5>
      <Button to={propLink}>Veja mais</Button>
    </S.Card>
  )
}

CardExam.defaultProps = {
  propName: 'Exam',
  propLink: '/',
}

CardExam.propTypes = {
  propName: PropTypes.string,
  propLink: PropTypes.string,
}

export default CardExam
