import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'
import bLink from '../Link'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 20px;
  border: 1px solid #258e914a;
  border-radius: 15px;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;

  width: 300px;
  height: 130px;

  h5 {
    color: #5F5E61;
  }

  transition: box-shadow 0.5s ease;
  text-decoration: none;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: box-shadow 0.5s;
    /* border: 1px solid #258e91;   */
    border: none;
  }
`

export const Link = styled(bLink)`
  display: inline-block;
  line-height: inherit;
  white-space: nowrap;
  margin: ${props => props.margin+'px'};
`

export const LogoWrapper = styled(Img)`
  margin: ${props => props.margin+'px'};
  /* margin: 30px; */
  /* padding: 30px; */
  /* border-radius: 50%;
  height: 3.75rem;
  margin: auto;
  width: 3.75rem;

  ${media.lessThan('large')`
    height: 1.875rem;
    width: 1.875rem;
  `} */
`
